@charset "UTF-8";
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.container > .content-wrap {
  min-width: calc(100% - 230px);
  margin-left: 230px;
  min-height: 100vh;
  background-color: #e7e3e0;
  right: 0;
  padding: 90px;
}
.container > .content-wrap > h2 {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  font-size: 50px;
  color: #333333;
}

.alert-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}
.alert-modal .modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 500px;
  height: 230px;
  background-color: white;
  border-radius: 10px;
  outline: 2px solid #cdcdcd;
}
.alert-modal .modal .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #434343;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top: 0;
}
.alert-modal .modal .head > svg {
  color: white;
  font-size: 35px;
}
.alert-modal .modal .content {
  text-align: center;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-size: 20px;
  margin-top: 50px;
  padding: 10px;
}
.alert-modal .modal .btn-wrap {
  margin-top: 10px;
}
.alert-modal .modal .btn-wrap button {
  padding: 10px 15px;
  background-color: #434343;
  color: white;
  margin-right: 15px;
  border-radius: 8px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.alert-modal .modal .btn-wrap button:last-child {
  margin-right: 0;
}

/* error-home | 에러 페이지 */
.error-home {
  width: 100%;
  height: 100vh;
  background: url("Image/error_bg.png") no-repeat center bottom/cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-home > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: rotate(7.53deg) scale(1.05);
  position: relative;
  width: 100%;
  height: 166px;
  background-color: #ffe509;
  margin-top: -150px;
  overflow: hidden;
}
.error-home > div:first-child span {
  font-size: 100px;
  white-space: nowrap;
  will-change: transform;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  animation: marquee 5000s linear infinite;
}
.error-home .content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 70px auto 0;
}
.error-home .content-container strong {
  margin: 16px 0 8px;
  font-size: 40px;
  color: #484848;
  line-height: 57.92px;
}
.error-home .content-container p {
  margin-bottom: 10px;
  font-size: 24px;
  color: #cecece;
}
.error-home .content-container a {
  height: 45px;
  margin-top: 10px;
  padding: 0 30px;
  background-color: #ffe509;
  border-radius: 45px;
  color: #434343;
  line-height: 45px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}

.sideBar {
  position: fixed;
  left: 0;
  top: 0;
  justify-content: flex-start;
  width: 230px;
  height: 100vh;
  background-color: #333333;
}
.sideBar > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.sideBar > a img {
  width: 60%;
  margin: 20px 0;
}
.sideBar > .mainMenu {
  width: 100%;
  margin-bottom: 60px;
  border-top: 1px solid #858585;
}
.sideBar > .mainMenu a {
  text-decoration: none;
}
.sideBar > .mainMenu li {
  cursor: pointer;
  text-justify: center;
  border-bottom: 1px solid #858585;
  color: white;
  padding: 10px 15px;
}
.sideBar > .mainMenu li:hover {
  background-color: #434343;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.sideBar > .mainMenu li.main {
  padding: 3px 15px;
  background-color: rgba(255, 255, 255, 0.15);
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  cursor: default;
}
.sideBar > .mainMenu li.active {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  background-color: rgba(0, 0, 0, 0.2);
}
.sideBar .logoutBtn {
  width: 230px;
  position: fixed;
  background-color: #434343;
  border-top: 1px solid #858585;
  text-align: center;
  left: 0;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  transition: all 0.3s;
}
.sideBar .logoutBtn:hover {
  transition: all 0.3s;
  background-color: #de5d60;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.topBar > h2 {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  font-size: 50px;
  color: #333333;
}
.topBar > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.topBar > div :first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.topBar > div :last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.topBar > div select {
  padding: 4px 20px;
  background-color: #ffffff;
  border: 1px solid #333333;
  border-right: none;
  font-size: 16px;
}
.topBar > div select:last-child {
  border-right: 1px solid #333333;
}
.topBar > div input[type=text] {
  height: 36px;
  border: 1px solid #333333;
  padding: 7px;
}
.topBar > div button {
  height: 36px;
  padding: 5px 20px;
  font-size: 16px;
  background-color: #333333;
  color: white;
}
.topBar > div button > .searchIcon {
  margin-left: 5px;
}
.topBar > div button > .searchIcon > svg {
  transform: translateY(2px);
}
.topBar > div button.deleteBtn, .topBar > div button.searchBtn, .topBar > div button.applyBtn {
  border-right: 1px solid white;
}

.pagination {
  margin-top: 50px;
}
.pagination > li {
  background-color: white;
  padding: 10px 0;
  width: 40px;
  text-align: center;
  border: 1px solid #cdcdcd;
  cursor: pointer;
  border-right: none;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  user-select: none;
}
.pagination > li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pagination > li:last-child {
  border-right: 1px solid #cdcdcd;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.pagination > li.now {
  background-color: #333333;
  color: white;
}
.pagination > li.active > svg > polyline {
  stroke: #333333;
}
.pagination > li.block {
  cursor: default;
}
.pagination > li.block > svg > polyline {
  stroke: #cdcdcd;
}
.pagination > li > svg {
  transform: scale(1.4);
}

.none-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5;
  height: 500px;
  font-size: 20px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.none-list.service {
  margin-top: 50px;
}

.table-wrap > table {
  width: 100%;
  padding: 10px;
  text-align: center;
  border-collapse: collapse;
}
.table-wrap > table thead {
  background-color: #f5f5f5;
}
.table-wrap > table thead tr {
  cursor: default;
}
.table-wrap > table tbody {
  background-color: rgba(255, 255, 255, 0.4);
}
.table-wrap > table tr {
  cursor: pointer;
}
.table-wrap > table tr > th {
  font-weight: normal;
  padding: 15px 0;
}
.table-wrap > table tr > td {
  padding: 20px 0;
}
.table-wrap > table tr:hover td {
  color: #406178;
}
.table-wrap > table tr > th,
.table-wrap > table tr td {
  border-bottom: 1px solid #cdcdcd;
}
.table-wrap > table tr th {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}

.modal-background {
  width: 100%;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-background .modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50vw;
  height: 700px;
  padding: 50px;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0px 0px 8px rgba(67, 67, 67, 0.6);
}
.modal-background .modal .topBar {
  width: 100%;
}
.modal-background .modal .topBar > div {
  cursor: pointer;
}
.modal-background .modal .topBar > div > table {
  box-shadow: 0px 0px 9px rgba(67, 67, 67, 0.3);
  border: 1px solid #cdcdcd;
  border-collapse: collapse;
}
.modal-background .modal .topBar > div > table thead th,
.modal-background .modal .topBar > div > table thead td,
.modal-background .modal .topBar > div > table tbody th,
.modal-background .modal .topBar > div > table tbody td {
  border-radius: 0;
  padding: 5px;
  border-bottom: 1px solid #cdcdcd;
}
.modal-background .modal .topBar > div > table thead th:first-child,
.modal-background .modal .topBar > div > table thead td:first-child,
.modal-background .modal .topBar > div > table tbody th:first-child,
.modal-background .modal .topBar > div > table tbody td:first-child {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  background-color: #f5f5f5;
}
.modal-background .modal .topBar > div > table thead th:nth-child(2),
.modal-background .modal .topBar > div > table thead td:nth-child(2),
.modal-background .modal .topBar > div > table tbody th:nth-child(2),
.modal-background .modal .topBar > div > table tbody td:nth-child(2) {
  background-color: white;
  text-align: right;
}
.modal-background .modal .topBar > div > svg {
  scale: 2;
  border-radius: 0;
  fill: #434343;
}
.modal-background .modal .topBar .viewBytes {
  box-shadow: 0px 0px 4px rgba(67, 67, 67, 0.5);
  padding: 5px;
  border-radius: 7px;
  margin-left: 10px;
  color: #434343;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.modal-background .modal .topBar .viewBytes > span {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
}
.modal-background .modal > hr {
  width: 100%;
  margin: 30px 0;
}
.modal-background .modal > .title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.modal-background .modal > .title-wrap > svg {
  font-size: 25px;
  margin-right: 10px;
  color: #434343;
  min-width: 30px;
}
.modal-background .modal > .title-wrap h1 {
  display: inline-block;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
}
.modal-background .modal > .context {
  overflow: auto;
  border: 1px solid #cdcdcd;
  padding: 20px;
  box-shadow: 0px 0px 4px rgba(67, 67, 67, 0.2);
  width: 100%;
  margin-top: 10px;
  min-height: 300px;
  border-radius: 8px;
}
.modal-background .modal > .context img {
  width: 100%;
}
.modal-background .modal > .answer-wrap,
.modal-background .modal .view-answer-wrap {
  margin-top: 30px;
  width: 100%;
}
.modal-background .modal > .answer-wrap > .header,
.modal-background .modal .view-answer-wrap > .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.modal-background .modal > .answer-wrap > .header h2,
.modal-background .modal .view-answer-wrap > .header h2 {
  display: inline-block;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
}
.modal-background .modal > .answer-wrap > .header svg,
.modal-background .modal .view-answer-wrap > .header svg {
  margin-right: 10px;
  font-size: 25px;
  color: #434343;
}
.modal-background .modal > .answer-wrap > .answer-area,
.modal-background .modal > .answer-wrap .view-answer-area,
.modal-background .modal .view-answer-wrap > .answer-area,
.modal-background .modal .view-answer-wrap .view-answer-area {
  margin-top: 10px;
  resize: none;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(67, 67, 67, 0.2);
  min-height: 200px;
  border: 1px solid #cdcdcd;
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
}
.modal-background .modal > .answer-wrap > .answer-area:focus,
.modal-background .modal > .answer-wrap .view-answer-area:focus,
.modal-background .modal .view-answer-wrap > .answer-area:focus,
.modal-background .modal .view-answer-wrap .view-answer-area:focus {
  outline: none;
  border: 1px solid #858585;
}
.modal-background .modal .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 5px;
}
.modal-background .modal .footer .viewBytes {
  box-shadow: 0px 0px 4px rgba(67, 67, 67, 0.5);
  padding: 5px;
  border-radius: 7px;
  color: #434343;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.modal-background .modal .footer .viewBytes > span {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
}
.modal-background .modal .footer button {
  background-color: #434343;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  margin-right: 5px;
}
.modal-background .modal .footer button:last-child {
  margin-right: 0;
}
.modal-background .modal .go-service {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.modal-background .modal .go-service > a {
  color: #858585;
  text-decoration: underline;
  text-underline-position: under;
}
.modal-background .modal.editor {
  width: 70vw !important;
  height: 80vh !important;
}
.modal-background .modal.editor .quill-wrap {
  width: 100%;
}
.modal-background .modal.editor .quill-wrap .quill {
  width: 100%;
}
.modal-background .modal.editor .quill-wrap .ql-editor {
  min-height: 500px;
  width: 100%;
}
.modal-background .modal.editor > .topBar select {
  padding: 10px;
  border: 1px solid #cdcdcd;
  border-right: none;
}
.modal-background .modal.editor > .topBar input,
.modal-background .modal.editor > .topBar textarea {
  border: 1px solid #cdcdcd !important;
  font-size: 26px;
  line-height: 28px;
  padding: 23px 15px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  resize: none;
}
.modal-background .modal.editor > .topBar input:focus,
.modal-background .modal.editor > .topBar textarea:focus {
  border: none;
  outline: 0;
}
.modal-background .modal.editor > .topBar input.long,
.modal-background .modal.editor > .topBar textarea.long {
  width: 750px;
}
.modal-background .modal.editor .footer {
  margin-top: 10px;
}
.modal-background .modal.editor .footer > div > button:last-child {
  margin-left: 5px;
}
.modal-background .modal.coupon {
  width: 500px;
  height: 430px;
}
.modal-background .modal.coupon .topBar > h2 {
  font-size: 30px;
}
.modal-background .modal.coupon .wrap {
  border: 1px solid #cdcdcd;
  padding: 20px;
  width: 100%;
}
.modal-background .modal.coupon .wrap input {
  border: 1px solid #cdcdcd;
  padding: 10px 20px;
  width: 100%;
}
.modal-background .modal.coupon .wrap p {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.modal-background .modal.coupon .wrap p::before {
  content: "*";
  color: #f74848;
}
.modal-background .modal.coupon .wrap .footer {
  margin-top: 20px;
  justify-content: center;
}
.modal-background .modal.coupon .wrap .footer > div > button:last-child {
  margin-left: 10px;
}
.modal-background .modal.cache {
  height: 90vh;
  padding-bottom: 0;
}
.modal-background .modal.cache .none-cache {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  margin-bottom: 50px;
}
.modal-background .modal.cache .codeBlock {
  width: 100%;
  margin-bottom: 20px;
}
.modal-background .modal.cache .codeBlock > div > span > code > span {
  font-family: "Cascadia", "NotoSansR", "sans-serif" !important;
}
.modal-background .modal.cache .pagination-wrap {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
}
.modal-background .modal.cache .pagination {
  margin-top: 0;
}
.modal-background .modal.cache .searchPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.modal-background .modal.cache .searchPage > input {
  border: 1px solid #cdcdcd;
  width: 50px;
  padding: 10px 0;
  text-align: center;
  border-radius: 8px;
  margin-right: 5px;
}
.modal-background .modal.cache .searchPage > button {
  background-color: #434343;
  padding: 10px;
  color: white;
  border-radius: 8px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  margin-left: 15px;
}
.modal-background .modal.service {
  width: 500px;
  height: 300px;
  position: relative;
}
.modal-background .modal.service > h2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #434343;
  color: white;
  padding: 15px 30px;
}
.modal-background .modal.service > .serviceInput {
  width: 100%;
  margin-top: 50px;
}
.modal-background .modal.service > .serviceInput > .row {
  width: 320px;
  justify-content: space-between;
}
.modal-background .modal.service > .serviceInput > .row span {
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  color: #434343;
}
.modal-background .modal.service > .serviceInput > .row input {
  margin-left: 10px;
  width: 70%;
  border: 1px solid #434343;
  padding: 5px 10px;
}
.modal-background .modal.service > .serviceInput > .row input:last-child {
  margin-top: 10px;
}
.modal-background .modal.service > .btn-wrap {
  margin-top: 30px;
}
.modal-background .modal.service > .btn-wrap > button {
  border-radius: 6px;
  padding: 10px 25px;
  background-color: #434343;
  color: white;
  margin-right: 20px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
}
.modal-background .modal.service > .btn-wrap > button:last-child {
  margin-right: 0;
}
.modal-background .modal.product {
  width: 500px;
  height: 370px;
  position: relative;
}
.modal-background .modal.product > h2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  color: white;
  background-color: #434343;
  padding: 15px 30px;
}
.modal-background .modal.product > .productInput {
  margin-top: 60px;
}
.modal-background .modal.product > .productInput .row {
  justify-content: space-between;
  width: 300px;
  margin-bottom: 5px;
}
.modal-background .modal.product > .productInput .row select {
  border: none;
  box-shadow: 0 0 3px rgba(67, 67, 67, 0.3);
  width: 200px;
  background-color: white;
  padding: 5px 10px;
}
.modal-background .modal.product > .productInput .row input {
  width: 200px;
  padding: 5px 10px;
  box-shadow: 0 0 3px rgba(67, 67, 67, 0.3);
}
.modal-background .modal.product > .productInput .row:last-child {
  margin-bottom: none;
}
.modal-background .modal.product > .btn-wrap {
  margin-top: 30px;
}
.modal-background .modal.product > .btn-wrap button {
  padding: 10px 20px;
  background-color: #434343;
  color: white;
  margin-right: 10px;
  font-size: 16px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  border-radius: 8px;
}
.modal-background .modal.product > .btn-wrap button:last-child {
  margin-right: none;
}
.modal-background .modal.personnel {
  width: 50%;
  height: 600px;
  justify-content: center;
  position: relative;
}
.modal-background .modal.personnel .topBar {
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 30px;
}
.modal-background .modal.personnel .topBar h3 {
  font-size: 30px;
  color: #434343;
}
.modal-background .modal.personnel .personnelInput {
  margin-top: 20px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(67, 67, 67, 0.2);
  width: 80%;
  height: 90%;
}
.modal-background .modal.personnel .personnelInput > .row {
  width: 90%;
  margin-bottom: 30px;
}
.modal-background .modal.personnel .personnelInput > .row:last-child {
  margin-bottom: 0;
}
.modal-background .modal.personnel .personnelInput > .row span {
  text-align: left;
  width: 100px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  color: #434343;
}
.modal-background .modal.personnel .personnelInput > .row > .row {
  width: 100%;
}
.modal-background .modal.personnel .personnelInput > .row > .row .infoInput {
  width: calc(100% - 100px);
}
.modal-background .modal.personnel .personnelInput > .row .name,
.modal-background .modal.personnel .personnelInput > .row .gender,
.modal-background .modal.personnel .personnelInput > .row .birth,
.modal-background .modal.personnel .personnelInput > .row .department {
  width: 47.5%;
}
.modal-background .modal.personnel .personnelInput > .row .blank {
  width: 5%;
}
.modal-background .modal.personnel .personnelInput > .row .infoInput {
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(67, 67, 67, 0.4);
  width: calc(100% - 100px);
  padding: 10px 5px;
}
.modal-background .modal.applyAdmin {
  overflow: hidden;
}
.modal-background .modal.applyAdmin .topBar h2 {
  font-size: 35px;
}
.modal-background .modal.applyAdmin button {
  background-color: #434343;
  color: white;
  padding: 10px 15px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  border-radius: 10px;
}
.modal-background .modal.applyAdmin button.duplicateCheck {
  width: 80px;
  margin-left: 10px;
}
.modal-background .modal.applyAdmin button.applyBtn, .modal-background .modal.applyAdmin button.deleteBtn {
  margin-top: 100px;
  padding: 10px 30px;
  font-size: 16px;
}
.modal-background .modal.applyAdmin button.deleteBtn {
  margin-left: 20px;
}
.modal-background .modal.applyAdmin .infoInput {
  margin-top: 100px;
  align-items: flex-start;
}
.modal-background .modal.applyAdmin .infoInput .idCheck {
  padding: 5px 0;
  margin-left: 100px;
  opacity: 0;
}
.modal-background .modal.applyAdmin .infoInput .idCheck.active {
  opacity: 1;
}
.modal-background .modal.applyAdmin .infoInput .idCheck.canUsed {
  color: #3c8fef;
}
.modal-background .modal.applyAdmin .infoInput .idCheck.cantUsed {
  color: #ff3838;
}
.modal-background .modal.applyAdmin .infoInput > .column {
  align-items: flex-start;
}
.modal-background .modal.applyAdmin .infoInput > .column:first-child {
  margin-right: 70px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row {
  margin-bottom: 20px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row span {
  width: 100px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row.id {
  margin-bottom: 0;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row input,
.modal-background .modal.applyAdmin .infoInput > .column > .row select,
.modal-background .modal.applyAdmin .infoInput > .column > .row .fixedInput {
  box-shadow: 0 0 3px #cdcdcd;
  width: 290px;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  background-color: white;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row input.idInput,
.modal-background .modal.applyAdmin .infoInput > .column > .row select.idInput,
.modal-background .modal.applyAdmin .infoInput > .column > .row .fixedInput.idInput {
  width: 200px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row .fixedInput {
  background-color: #f5f5f5;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row input[type=radio] {
  box-shadow: none;
  width: 30px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row .genderBox {
  cursor: pointer;
  width: 135px;
  box-shadow: 0 0 3px #cdcdcd;
  padding: 10px 15px;
  margin-right: 20px;
  border-radius: 10px;
}
.modal-background .modal.applyAdmin .infoInput > .column > .row .genderBox:last-child {
  margin-right: 0;
}
.modal-background .modal.applyPopup {
  height: 750px;
}
.modal-background .modal.applyPopup h2 {
  font-size: 35px;
}
.modal-background .modal.applyPopup > .column {
  margin-top: 30px;
  width: 100%;
}
.modal-background .modal.applyPopup > .column > input[type=file] {
  display: none;
}
.modal-background .modal.applyPopup > .column label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}
.modal-background .modal.applyPopup > .column .imageInput {
  background-color: #fafafa;
  border: 2px dashed #434343;
  border-radius: 16px;
  width: 60%;
  color: #434343;
  min-height: 200px;
  height: auto;
  margin-bottom: 40px;
  overflow-y: hidden;
}
.modal-background .modal.applyPopup > .column .imageInput > img {
  width: 100%;
}
.modal-background .modal.applyPopup > .column .imageInput > svg {
  font-size: 100px;
}
.modal-background .modal.applyPopup > .column .imageInput > span {
  font-size: 13px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.modal-background .modal.applyPopup > .column .imageInput.enter {
  background-color: #dddddd;
}
.modal-background .modal.applyPopup > .column > .row {
  margin-bottom: 20px;
}
.modal-background .modal.applyPopup > .column > .row:last-child {
  margin-bottom: 40px;
}
.modal-background .modal.applyPopup > .column > .row > span {
  width: 100px;
}
.modal-background .modal.applyPopup > .column > .row input[type=text],
.modal-background .modal.applyPopup > .column > .row .dateInput,
.modal-background .modal.applyPopup > .column > .row select {
  background-color: white;
  border: none;
  width: 400px;
  box-shadow: 0 0 3px rgba(67, 67, 67, 0.4);
  padding: 7px 10px;
  border-radius: 8px;
  min-height: 34px;
}
.modal-background .modal.applyPopup > .column > .row input[type=text] > input[type=time],
.modal-background .modal.applyPopup > .column > .row .dateInput > input[type=time],
.modal-background .modal.applyPopup > .column > .row select > input[type=time] {
  margin-left: 30px;
}
.modal-background .modal.applyPopup .btnWrap > button {
  background-color: #434343;
  color: white;
  padding: 10px 20px;
  font-family: "NotoSansB", "맑은 고딕", "sans-serif";
  border-radius: 10px;
  font-size: 15px;
  margin-right: 10px;
}
.modal-background .modal.applyPopup .btnWrap > button:last-child {
  margin-right: 0;
}
.modal-background .modal.new-coupon {
  height: auto;
  width: 40%;
}
.modal-background .modal.new-coupon h2 {
  font-size: 30px;
}
.modal-background .modal.new-coupon .column {
  align-items: flex-start;
  padding: 50px 0;
}
.modal-background .modal.new-coupon .column .row {
  margin-bottom: 30px;
}
.modal-background .modal.new-coupon .column .row span {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  text-align: left;
  width: 100px;
  margin-right: 40px;
}
.modal-background .modal.new-coupon .column .row select {
  width: 200px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: none;
}
.modal-background .modal.new-coupon .column .row input {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 200px;
  border-radius: 8px;
}
.modal-background .modal.new-coupon .column p {
  font-size: 12px;
  color: #333333;
}
.modal-background .modal.new-coupon .footer {
  margin-top: -30px;
  width: auto;
}
.modal-background .modal.qna-detail > .column, .modal-background .modal.qna-write > .column {
  margin-top: 40px;
  width: 100%;
}
.modal-background .modal.qna-detail > .column hr, .modal-background .modal.qna-write > .column hr {
  width: 100%;
  height: 1px;
  margin: 50px 0;
  border: none;
  background-color: #cdcdcd;
}
.modal-background .modal.qna-detail > .column .icon, .modal-background .modal.qna-write > .column .icon {
  font-size: 30px;
  width: 100%;
  position: relative;
}
.modal-background .modal.qna-detail > .column .icon svg, .modal-background .modal.qna-write > .column .icon svg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  transform: translate(-20px, -20px);
  opacity: 0.2;
}
.modal-background .modal.qna-detail > .column .content-area,
.modal-background .modal.qna-detail > .column textarea, .modal-background .modal.qna-write > .column .content-area,
.modal-background .modal.qna-write > .column textarea {
  z-index: 6;
  width: 100%;
  background-color: white;
  min-height: 300px;
  border: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 16px;
  resize: none;
}
.modal-background .modal.qna-detail > .column .content-area:focus,
.modal-background .modal.qna-detail > .column textarea:focus, .modal-background .modal.qna-write > .column .content-area:focus,
.modal-background .modal.qna-write > .column textarea:focus {
  outline: none;
}
.modal-background .modal.qna-detail > .column .content-area::placeholder,
.modal-background .modal.qna-detail > .column textarea::placeholder, .modal-background .modal.qna-write > .column .content-area::placeholder,
.modal-background .modal.qna-write > .column textarea::placeholder {
  color: #cdcdcd;
}
.modal-background .modal.qna-detail > .column .byte, .modal-background .modal.qna-write > .column .byte {
  padding: 5px 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal-background .modal.qna-detail > .column .byte p, .modal-background .modal.qna-write > .column .byte p {
  display: inline-block;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.modal-background .modal.qna-detail > .column .qna-keyword-list, .modal-background .modal.qna-write > .column .qna-keyword-list {
  width: 100%;
  padding: 5px 10px;
  margin-top: 40px;
  min-height: 50px;
  border-radius: 8px;
  flex-wrap: wrap;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}
.modal-background .modal.qna-detail > .column .qna-keyword-list span, .modal-background .modal.qna-write > .column .qna-keyword-list span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  background-color: #f5f5f5;
  padding: 3px 10px;
  border-radius: 15px;
  margin: 3px;
}
.modal-background .modal.qna-detail > .column .qna-keyword-list span svg, .modal-background .modal.qna-write > .column .qna-keyword-list span svg {
  font-size: 16px;
}
.modal-background .modal.qna-detail > .column .qna-keyword-list span svg.hashtagIcon, .modal-background .modal.qna-write > .column .qna-keyword-list span svg.hashtagIcon {
  margin-right: 10px;
}
.modal-background .modal.qna-detail > .column .qna-keyword-list span svg.closeIcon, .modal-background .modal.qna-write > .column .qna-keyword-list span svg.closeIcon {
  margin-left: 10px;
  cursor: pointer;
}
.modal-background .modal.qna-detail > .column .qna-keyword-input-wrap, .modal-background .modal.qna-write > .column .qna-keyword-input-wrap {
  margin-top: 20px;
}
.modal-background .modal.qna-detail > .column .qna-keyword-input-wrap .qna-keyword-input, .modal-background .modal.qna-write > .column .qna-keyword-input-wrap .qna-keyword-input {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 40px;
  width: 300px;
}
.modal-background .modal.qna-detail > .column .qna-keyword-input-wrap .applyBtn, .modal-background .modal.qna-write > .column .qna-keyword-input-wrap .applyBtn {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  height: 40px;
  padding: 0px 15px;
  background-color: #434343;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: white;
}
.modal-background .modal.qna-detail .btn-wrap, .modal-background .modal.qna-write .btn-wrap {
  margin-top: 40px;
}
.modal-background .modal.qna-detail .btn-wrap button, .modal-background .modal.qna-write .btn-wrap button {
  padding: 10px 15px;
  background-color: #434343;
  color: white;
  border-radius: 8px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-size: 16px;
}
.modal-background .modal.qna-detail .btn-wrap button:first-child, .modal-background .modal.qna-write .btn-wrap button:first-child {
  margin-right: 10px;
}

@media screen and (max-width: 1440px) {
  .container .content-wrap {
    padding: 50px;
  }
  .table-wrap table {
    font-size: 14px;
  }
}